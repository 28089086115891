import React, {
    useMemo,
    useState
} from "react";
import axios from "axios";
import {
    Alert,
    Button,
    Form,
    Modal
} from "react-bootstrap";

function EnergyAlgorithmSettingModal({ inverter, show, handleClose, refreshInverters }) {
    const [energyAlgorithms, setEnergyAlgorithms] = useState(null);
    const [state, setState] = useState(undefined);
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState(null);

    const onShow = useMemo(() => {
        return async () => {
            if(!inverter) {
                return;
            }
            setError(null);
            setSaving(false);
            setState(inverter.energyAlgorithm?.value);

            try {
                const response = await axios.get("/getEnergyAlgorithms");
                setEnergyAlgorithms(response.data.algorithms);
            } catch(error) {
                console.error(error);
                setError("Er is iets fout gegaan. Probeer het later opnieuw.");
            }
        }
    }, [inverter]);
    const submit = useMemo(() => {
        return async () => {
            setSaving(true);
            try {
                await axios.post("/setInverterEnergyAlgorithm", {
                    inverterId: inverter.id,
                    energyAlgorithm: state
                });
                setSaving(false);
                refreshInverters();
                handleClose();
            } catch(error) {
                console.error(error);
                setError("Er is iets fout gegaan. Probeer het later opnieuw.");
                setSaving(false);
            }
        }
    }, [inverter, state, refreshInverters, handleClose]);
    return (
        <Modal show={ show } onHide={ handleClose } onShow={ onShow }>
            <Modal.Header closeButton>
                <Modal.Title>Energie algoritme aanpassen</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { error && (
                    <Alert variant="danger">{ error }</Alert>
                )}
                <p>
                    Hier kun je het energie algoritme van de omvormer instellen.
                </p>
                <Form.Control
                    as="select"
                    value={ state }
                    onChange={ (event) => setState(event.target.value) }
                    disabled={ saving || !energyAlgorithms }
                >
                    { !inverter?.energyAlgorithm && (
                        <option value=""/>
                    )}
                    { energyAlgorithms && energyAlgorithms.map((energyAlgorithm) => (
                        <option
                            key={ energyAlgorithm.value }
                            value={ energyAlgorithm.value }
                        >
                            { energyAlgorithm.name }
                        </option>
                    )) }
                </Form.Control>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={ handleClose } disabled={ saving }>
                    Annuleer
                </Button>
                <Button variant="primary" onClick={ submit } disabled={ saving }>
                    Toepassen
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default React.memo(EnergyAlgorithmSettingModal);
